import gql from 'graphql-tag'

export const Promotions = gql`
  query promotions {
    promotions {
      id
      name
      code
      value
      promotionType
    }
  } 
`
