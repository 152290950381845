<template>
  <div>
    <template
      v-for="promotion in promotions"
      >
      <promotion
        @reload="fetchPromotions"
        :promotion="promotion"
        ></promotion>
    </template>
  </div>
</template>

<script>
import { Promotions } from '@/graphql/queries/promotions'
const Promotion = () => import('@/components/admin/promotions/Promotion')

export default {
  data: () => ({
    promotions: []
  }),

  created () {
    this.fetchPromotions()
  },

  components: { Promotion },

  methods: {
    fetchPromotions () {
      this.$apollo.query ({
        query: Promotions,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.promotions = res.data.promotions

        if (res.data.promotions.find ( promotion => !promotion.code && promotion.name == 'Primera compra' )) {
          this.$emit('firstOrder', true)
        }
      })
    }
  }
}
</script>
